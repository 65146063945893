import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import * as Styles from "../styles/access.module.scss"

const AccessPage = () => {
    const DATA = useStaticQuery(
        graphql`
            query {
                site { 
                    siteMetadata {
                        divisionJa { support },
                        menuJa { access },
                        name,
                        address { postalCode, address },
                        tel { print, code, receptionHours },
                        gmapAddress
                    }
                }
            }
        `
    )
    const SUPPORT_JA = DATA.site.siteMetadata.divisionJa.support
    const ACCESS_JA = DATA.site.siteMetadata.menuJa.access
    return (
        <Layout division='support'>
            <Seo title={`${ACCESS_JA} | ${SUPPORT_JA}`} />
            <h1 className="support">{ACCESS_JA}</h1>

            <div className={`${Styles.support} ${Styles.table}`}>
                <div className={Styles.table_body}>
                    <div className={Styles.table_col}>
                        <span className={Styles.table_key}>事務所名</span>
                        <span className={Styles.table_val}>{SUPPORT_JA}</span>
                    </div>
                    <div className={Styles.table_col}>
                        <span className={Styles.table_key}>代表</span>
                        <span className={Styles.table_val}>{DATA.site.siteMetadata.name}</span>
                    </div>
                    <div className={Styles.table_col}>
                        <span className={Styles.table_key}>住所</span>
                        <span className={Styles.table_val}>{DATA.site.siteMetadata.address.postalCode}<br />{DATA.site.siteMetadata.address.address}</span>
                    </div>
                    <div className={Styles.table_col}>
                        <span className={Styles.table_key}>電話番号</span>
                        <span className={Styles.table_val}>
                            <a href={`tel:${DATA.site.siteMetadata.tel.code}`}>{DATA.site.siteMetadata.tel.print}</a><br />
                            <span className={Styles.small_letters}>{DATA.site.siteMetadata.tel.receptionHours}</span>
                        </span>
                    </div>
                    <div className={Styles.table_col}>
                        <span className={Styles.table_key}>取扱業務</span>
                        <span className={Styles.table_val}>IT導入支援<br />Webサイト作成<br />事務代行<br />事業主サポート</span>
                    </div>
                </div>
            </div>

            <div className={Styles.ggmap}>
                <iframe src={DATA.site.siteMetadata.gmapAddress} loading="lazy"></iframe>
            </div>
        </Layout>
    )
}

export default AccessPage
